
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";

@Options({
  title: 'Performance Report',
  components: {},
})

export default class PerformanceReport extends mixins(UtilityOptions) {
  private lists  = [];
  private reportService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Performance Report", to: "performance-report" },
  ];

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    reportType: {name:'Sales',value: 'INE'},
    filterType: "None",
    storeID: 0,
    condition: '>',
    dimension: 'Customer',
    sort: 'DESC',
    limit: 10,
    amountValue: 0,
  };


  private dimensions = [
    {
      name: 'Customer',
    },
    {
      name: 'User',
    },
    {
      name: 'Brand',
    },
    {
      name: 'Brand Sector',
    },
    {
      name: 'Product Type',
    },
    {
      name: 'Category',
    },
    {
      name: 'Stores',
    },
  ];

   private reportTypes = [
    {
      name: 'Sales',
      value: 'INE'
    },
    {
      name: 'Refund',
      value: 'RFD'
    },
    {
      name: 'Purchase',
      value: 'PUR'
    },
    {
      name: 'Purchase Return',
      value: 'RPU'
    },
    {
      name: 'Transfer',
      value: 'TRN'
    }
  ];

  private sortList = [
    {
      name: 'Sort by Ascending Order',
      value: 'ASC'
    },
    {
      name: 'Sort by Descending Order',
      value: 'DESC'
    }
  ];

  private conditions = [
    {
      name: '>',
      value:">"
    },
    {
      name: '<',
       value:"<"
    },
    {
      name: '<=',
      value:"<="
    },
    {
      name: '>=',
      value:">="
    },
    {
      name: '=',
      value:"="
    }
  ];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.performanceReport(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  get storeHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'code', header: 'Store Code'},
      {field: 'name', header: 'Store Name'},
      {field: 'contact', header: 'Contact No'},
      {field: 'email', header: 'Email Address'},
      {field: 'totalAmount', header: 'Total'},
    ];
  }

  get customerHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'customerName', header: 'Customer Name'},
      {field: 'emailAddress', header: 'Customer Email'},
      {field: 'customerContact', header: 'Customer Contact'},
      {field: 'totalAmount', header: 'Total Amount'},
    ];
  }
  
  get userHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'name', header: 'User Name'},
      {field: 'email', header: 'User Email'},
      {field: 'contact', header: 'User Contact'},
      {field: 'totalAmount', header: 'Total Amount'},
    ];
  }

  get brandHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'name', header: 'Brand Name'},
      {field: 'totalQty', header: 'Total Qty'},
      {field: 'totalAmount', header: 'Subtotal'},
    ];
  }

  get sectorHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'name', header: 'Sector Name'},
      {field: 'totalQty', header: 'Total Qty'},
      {field: 'totalAmount', header: 'Subtotal'},
    ];
  }

  get productHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'name', header: 'Product Type'},
      {field: 'totalQty', header: 'Total Qty'},
      {field: 'totalAmount', header: 'Subtotal'},
    ];
  }

  get categoryHeaders()
  {
    return  [
      {field: 'ctr', header: 'SNO'},
      {field: 'name', header: 'Category Name'},
      {field: 'totalQty', header: 'Total Qty'},
      {field: 'totalAmount', header: 'Subtotal'},
    ];
  }
  

  get tableHeaders()
  {
    let headers:any = [];

    if(this.searchFilters.dimension == 'Stores')
    {
      headers = this.storeHeaders;
    }
    else if(this.searchFilters.dimension == 'Customer')
    {
      headers = this.customerHeaders;
    }
    else if(this.searchFilters.dimension == 'User')
    {
      headers = this.userHeaders;
    }
    else if(this.searchFilters.dimension == 'Category')
    {
      headers = this.categoryHeaders;
    }
    else if(this.searchFilters.dimension == 'Brand Sector')
    {
      headers = this.sectorHeaders;
    }
    else if(this.searchFilters.dimension == 'Brand')
    {
      headers = this.brandHeaders;
    }
    else if(this.searchFilters.dimension == 'Product Type')
    {
      headers = this.productHeaders;
    }

    this.lists = [];

    return headers;
  }

   get currency() {
    return this.store.getters.getCurrency;
  }

}
