
import { Options, mixins } from "vue-class-component";
import StoreReports from "../../service/StoreReports";
import UtilityOptions from "../../mixins/UtilityOptions";
import ProfilerService from "../../service/ProfilerService.js";
import UserService from "../../service/UserService.js";
import AutoComplete from "primevue/autocomplete";

interface IReport {
afterDisc: number;
batchNo: string;
branchCode: string;
branchName: string;
brandName: string;
categoryName: string;
createdAt: string;
customerContact: string;
customerName: string;
expiryDate: string;
freeUnit: number;
genericName: string;
id: number;
itemDescription: string;
itemDisc: number;
itemName: string;
mode: string;
mrp: number;
packSize: number;
posReceiptId: number;
productType: string;
purchaseDisc: number;
purchasePrice: number;
receiptDate: string;
receiptNo: string;
sectorName: string;
sellingPrice: number;
sheetSize: number;
stockId: number;
subTotal: number;
supplierBonus: number;
tax1: number;
tax2: number;
tax3: number;
totalUnit: number;
unit: number;
updatedAt: string;
userContact: string;
userName: string;
taxNumber1: number;
taxNumber2: number;
taxNumber3: number;
}

@Options({
  title: 'Tax Report',
  components: {AutoComplete},
})

export default class TaxReport extends mixins(UtilityOptions) {
  private lists: IReport []  = [];
  private profilerList = [];
  private userList = [];
  private reportService;
  private profilerService;
  private userService;
  private resultTitle = "";
  private productDialog = false;
  private loading = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Reports", to: "reports" },
    { label: "Tax Report", to: "tax-report" },
  ];

  private searchFilters = {
    id: "",
    date1: "",
    date2: "",
    filterType: "None",
    storeID: 0,
    type: 'Sales',
    customerID: 0,
    userID: 0,
    brandName: 'All',
    sectorName: 'All',
    categoryName: 'All',
    productType: 'All',
    batchNo: '',
    customerName: 'All',
    UserName: 'All',
  };

  private taxNames = [
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
    {
      taxName: "",
      show: false,
      optionalReq: "",
      taxValue: 0,
      accountHead: "",
      accountID: 0,
    },
  ];

  private reportTypes = [
    {
      name: 'Sales',
    },
    {
      name: 'Refund',
    },
    {
      name: 'Transfer',
    },
    {
      name: 'Purchase',
    },
    {
      name: 'Purchase Return',
    },
  ];

  private brands:any = [];
  private sectors:any = [];
  private categories:any = [];
  private productTypes:any = [];

  private filterDates = [];
  private dialogTitle;
  private submitted = false;
  private filterBranch = [];

  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.reportService = new StoreReports();
    this.profilerService = new ProfilerService();
    this.userService = new UserService();
  }
  
   //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.storeList();
    this.loadList();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {       
    this.submitted = false;
    this.dialogTitle = "Filter Report";
    this.productDialog = true;
  }

  storeList()
  {
    this.reportService.getFilterList().then((res) => {
      this.filterBranch  = res.stores;
      this.filterDates   = res.datesList;
      this.brands        = res.brands;
      this.sectors       = res.brandSector;
      this.categories    = res.categories;
      this.productTypes  = res.productTypes;

      const defaultFilter = {id:0,option_name:'All'};
      this.brands.push(defaultFilter);
      this.sectors.push(defaultFilter);
      this.categories.push(defaultFilter);
      this.productTypes.push(defaultFilter);

      this.taxNames = [];

        this.taxNames.push({
          taxName: res.storeTaxes[0].tax_name_1,
          show: res.storeTaxes[0].show_1,
          optionalReq: res.storeTaxes[0].required_optional_1,
          taxValue:
            res.storeTaxes[0].show_1 == "true"
              ? Number(res.storeTaxes[0].tax_value_1)
              : 0,
          accountHead: res.storeTaxes[0].tax_name1.chartName,
          accountID: res.storeTaxes[0].link1,
        });

        this.taxNames.push({
          taxName: res.storeTaxes[0].tax_name_2,
          show: res.storeTaxes[0].show_2,
          optionalReq: res.storeTaxes[0].required_optional_2,
          taxValue:
            res.storeTaxes[0].show_2 == "true"
              ? Number(res.storeTaxes[0].tax_value_2)
              : 0,
          accountHead: res.storeTaxes[0].tax_name2.chartName,
          accountID: res.storeTaxes[0].link2,
        });

        this.taxNames.push({
          taxName: res.storeTaxes[0].tax_name_3,
          show: res.storeTaxes[0].show_3,
          optionalReq: res.storeTaxes[0].required_optional_3,
          taxValue:
            res.storeTaxes[0].show_3 == "true"
              ? Number(res.storeTaxes[0].tax_value_3)
              : 0,
          accountHead: res.storeTaxes[0].tax_name3.chartName,
          accountID: res.storeTaxes[0].link3,
        });
    });
  }
 
  // USED TO GET SEARCHED ASSOCIATE
  loadList() {
    this.loading = true;
    this.reportService.taxReport(this.searchFilters).then((res) => {
        const data = this.camelizeKeys(res);
        this.resultTitle = data.resultTitle;
        this.lists = data.record;
        this.loading = false;
      });
    this.productDialog = false;
  }

  searchProfiler(event) {
    setTimeout(() => {
      this.profilerService.searchProfiler(event.query.trim()).then((data) => {
        this.profilerList = data.records;
      });
    }, 200);
  }
  
  searchUser(event) {
    setTimeout(() => {
      this.userService.searchUser(event.query.trim()).then((data) => {
        this.userList = data.records;
      });
    }, 200);
  }

  saveProfile(event) {
    const profileInfo = event.value;
    this.searchFilters.customerName = profileInfo.account_title;
    this.searchFilters.customerID = profileInfo.id;
  }
  
  saveUser(event) {
    const userInfo = event.value;
    this.searchFilters.UserName = userInfo.name;
    this.searchFilters.userID = userInfo.id;
  }

  get taxReport()
  {
    let l: IReport [] = [];

   this.lists.map(e => {
      l.push({
        afterDisc: Number(e.afterDisc),
        batchNo: e.batchNo,
        branchCode: e.branchCode,
        branchName: e.branchName,
        brandName: e.brandName,
        categoryName: e.categoryName,
        createdAt: e.createdAt,
        customerContact: e.customerContact,
        customerName: e.customerName,
        expiryDate: e.expiryDate,
        freeUnit: Number(e.freeUnit),
        genericName: e.genericName,
        id: e.id,
        itemDescription: e.itemDescription,
        itemDisc: Number(e.itemDisc),
        itemName: e.itemName,
        mode: e.mode,
        mrp: Number(e.mrp),
        packSize: Number(e.packSize),
        posReceiptId: e.posReceiptId,
        productType: e.productType,
        purchaseDisc: Number(e.purchaseDisc),
        purchasePrice: Number(e.purchasePrice),
        receiptDate: e.receiptDate,
        receiptNo: e.receiptNo,
        sectorName: e.sectorName,
        sellingPrice: Number(e.sellingPrice),
        sheetSize: Number(e.sheetSize),
        stockId: e.stockId,
        subTotal: Number(e.subTotal),
        supplierBonus: Number(e.supplierBonus),
        tax1: Number(e.tax1),
        tax2: Number(e.tax2),
        tax3: Number(e.tax3),
        totalUnit: Number(e.totalUnit),
        unit: Number(e.unit),
        updatedAt: e.updatedAt,
        userContact: e.userContact,
        userName: e.userName,
        taxNumber1: Number(this.calculateTaxAmt(e,'Tax1')),
        taxNumber2: Number(this.calculateTaxAmt(e,'Tax2')),
        taxNumber3: Number(this.calculateTaxAmt(e,'Tax3')),
      });
    });
    
    return l;
  }

  calculateTaxAmt(r: IReport,type)
  {
    let total = 0;
    let disc = 0;
    let price = 0;

    if(this.searchFilters.type == 'Sales' || this.searchFilters.type == 'Refund' || this.searchFilters.type == 'Transfer')
    {
      disc  = r.itemDisc;
      price = r.sellingPrice;
    }
    else
    {
      disc  = r.purchaseDisc;
      price = r.purchasePrice;
    }



    if(type == 'Tax1')
    {
      const s = r.unit * price;
      const d = (s/100)*disc;
      const p = s - d;
      total   = (p/100)*(r.tax1);
    }
    else if(type == 'Tax2')
    {
      const s = r.unit * price;
      const d = (s/100)*disc;
      const p = s - d;
      total   = (p/100)*(r.tax2);
    }
    else if(type == 'Tax3')
    {
      const s = r.unit * price;
      const d = (s/100)*disc;
      const p = s - d;
      total   = (p/100)*(r.tax3);
    }

    return total;
  }

  get totalTax1Amt()
  {
    let total = 0;
    this.taxReport.forEach(e => {
        total = total + e.taxNumber1;
    });
    return total;
  }
  
  get totalTax2Amt()
  {
    let total = 0;
    this.taxReport.forEach(e => {
        total = total + e.taxNumber1;
    });
    return total;
  }
  
  get totalTax3Amt()
  {
    let total = 0;
    this.taxReport.forEach(e => {
        total = total + e.taxNumber1;
    });
    return total;
  }

  get currency() {
    return this.store.getters.getCurrency;
  }
}
